import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Menu,
  MenuItem as MUIMenuItem,
  Badge,
  IconButton,
} from "@mui/material";
import { Button, Icon } from "zds";
import { type ActionItem, MenuItemsSource, type MenuItem } from "../constants";
import { orderBy } from "lodash";
import { ButtonVariantTypes } from "../../ZButtonWidget/constants";

const getVisibleItems = (action: ActionItem) => {
  const { configureMenuItems, menuItems, menuItemsSource, sourceData } = action;
  if (menuItemsSource === MenuItemsSource.STATIC) {
    const visibleItems = Object.keys(menuItems)
      .map((itemKey) => menuItems[itemKey])
      .filter((item) => item.isVisible === true);

    return orderBy(visibleItems, ["index"], ["asc"]);
  } else if (
    menuItemsSource === MenuItemsSource.DYNAMIC &&
    Array.isArray(sourceData) &&
    sourceData.length &&
    configureMenuItems?.config
  ) {
    const { config } = configureMenuItems;
    const getValue = (propertyName: keyof MenuItem, index: number) => {
      const value = config[propertyName];

      if (Array.isArray(value)) {
        return value[index];
      }

      return value ?? null;
    };

    const visibleItems = sourceData
      .map((item, index) => ({
        ...item,
        id: index.toString(),
        isVisible: getValue("isVisible", index),
        isDisabled: getValue("isDisabled", index),
        index: index,
        widgetId: "",
        label: getValue("label", index),
        onClick: config?.onClick,
        icon: getValue("icon", index),
        startIcon: getValue("startIcon", index),
        endIcon: getValue("endIcon", index),
      }))
      .filter((item) => item.isVisible === true);

    return visibleItems;
  }

  return [];
};

const RenderAction = ({
  action,
  onButtonClick,
}: {
  action: ActionItem;
  onButtonClick: (onClick: string | undefined) => void;
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const { color, label, onClick, type, value } = action;
  // Typography
  const { fontFamily, fontSize, fontStyle, fontWeight } = action;
  // Button
  const { badgeContent, endIcon, icon, isDisabled, startIcon, variant } =
    action;
  // Image
  const { imageHeight = 40, imageWidth = 40 } = action;

  switch (type) {
    case "Typography":
    default:
      return (
        <Box alignItems="center" display="flex">
          {startIcon && (
            <Box
              sx={{
                marginRight: "8px",
                marginTop: "4px",
                "& .MuiIcon-root": { color: color },
              }}
            >
              <Icon body={startIcon} />
            </Box>
          )}
          <Typography
            style={{
              fontFamily: fontFamily,
              fontSize: fontSize,
              fontWeight: fontWeight,
              fontStyle: fontStyle,
              color: color,
            }}
          >
            {value}
          </Typography>
          {endIcon && (
            <Box
              sx={{
                marginLeft: "8px",
                marginTop: "4px",
                "& .MuiIcon-root": { color: color },
              }}
            >
              <Icon body={endIcon} />
            </Box>
          )}
        </Box>
      );
    case "Image":
      return (
        <img
          alt="Action"
          src={value}
          style={{ width: imageWidth, height: imageHeight }}
        />
      );
    case "Button":
      if (icon) {
        return (
          <IconButton
            onClick={() => onButtonClick(onClick)}
            size="large"
            sx={{
              "& .MuiIcon-root": { color: color },
            }}
          >
            <Badge badgeContent={badgeContent} color="error" max={99}>
              <Icon body={icon} />
            </Badge>
          </IconButton>
        );
      } else {
        return (
          <Button
            disabled={isDisabled}
            dsOnClick={() => onButtonClick(onClick)}
            endIcon={endIcon}
            icon={icon}
            startIcon={startIcon}
            sx={{
              backgroundColor:
                variant === ButtonVariantTypes.CONTAINED && !icon && !isDisabled
                  ? `${color} !important`
                  : undefined,
              color:
                variant !== ButtonVariantTypes.CONTAINED || icon
                  ? color ?? "inherit"
                  : undefined,
              borderColor:
                variant !== ButtonVariantTypes.CONTAINED
                  ? color ?? "inherit"
                  : undefined,
              fontFamily: "inherit",
              fontWeight: 400,
              textTransform: "unset",
            }}
            variant={variant}
          >
            {label}
          </Button>
        );
      }

    case "Menu":
      const visibleMenuItems = getVisibleItems(action);
      return (
        <>
          {icon ? (
            <IconButton
              onClick={handleMenuOpen}
              size="large"
              sx={{
                "& .MuiIcon-root": { color: color },
              }}
            >
              <Badge badgeContent={badgeContent} color="error" max={99}>
                <Icon body={icon} />
              </Badge>
            </IconButton>
          ) : (
            <Button
              disabled={isDisabled}
              dsOnClick={handleMenuOpen}
              endIcon={endIcon}
              icon={icon}
              startIcon={startIcon}
              sx={{
                backgroundColor:
                  variant === ButtonVariantTypes.CONTAINED &&
                  !icon &&
                  !isDisabled
                    ? `${color} !important`
                    : undefined,
                color:
                  variant !== ButtonVariantTypes.CONTAINED || icon
                    ? color ?? "inherit"
                    : undefined,
                borderColor:
                  variant !== ButtonVariantTypes.CONTAINED
                    ? color ?? "inherit"
                    : undefined,
                fontFamily: "inherit",
                fontWeight: 400,
                textTransform: "unset",
              }}
            >
              {label}
            </Button>
          )}
          <Menu
            MenuListProps={{ "aria-labelledby": "basic-button" }}
            anchorEl={menuAnchorEl}
            onClose={handleMenuClose}
            open={Boolean(menuAnchorEl)}
          >
            {visibleMenuItems &&
              visibleMenuItems.map((item, index) => (
                <MUIMenuItem
                  key={index}
                  onClick={() => {
                    onButtonClick(item.onClick);
                    handleMenuClose();
                  }}
                >
                  {item.startIcon && <Icon body={item.startIcon} />}
                  {item.label}
                  {item.endIcon && <Icon body={item.endIcon} />}
                </MUIMenuItem>
              ))}
          </Menu>
        </>
      );
  }
};
function AppBarComponent(props: AppBarComponentProps) {
  const {
    backgroundColor = "primary",
    height = 64,
    leftActions = {},
    onButtonClick,
    rightActions = {},
  } = props;

  return (
    <AppBar position="static" sx={{ backgroundColor }}>
      <Toolbar sx={{ "&.MuiToolbar-root": { minHeight: height } }}>
        <Box alignItems="center" display="flex">
          {leftActions &&
            Object.values(leftActions).map((action, index) => (
              <Box
                key={index}
                sx={{ display: "flex", alignItems: "center", mr: 1 }}
              >
                <RenderAction action={action} onButtonClick={onButtonClick} />
              </Box>
            ))}
        </Box>
        <Box flexGrow={1} />
        {rightActions &&
          Object.values(rightActions).map((action, index) => (
            <Box
              key={index}
              sx={{ display: "flex", alignItems: "center", ml: 1 }}
            >
              <RenderAction action={action} onButtonClick={onButtonClick} />
            </Box>
          ))}
      </Toolbar>
    </AppBar>
  );
}
export interface AppBarComponentProps {
  leftActions?: Record<string, ActionItem>;
  rightActions?: Record<string, ActionItem>;
  onButtonClick: (onClick: string | undefined) => void;
  backgroundColor: string;
  height: number;
}
export default AppBarComponent;
