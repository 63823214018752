import { ValidationTypes } from "constants/WidgetValidation";
import {
  ColumnTypes,
  type ZTableWidgetProps,
} from "widgets/ZTableWidget/constants";
import {
  getBasePropertyPath,
  hideByColumnType,
  hideByMenuItemsSource,
  hideIfMenuItemsSourceDataIsFalsy,
  updateIconAlignment,
  updateMenuItemsSource,
} from "../../propertyUtils";
import { MenuItemsSource } from "widgets/MenuButtonWidget/constants";
import { EvaluationSubstitutionType } from "entities/DataTree/dataTreeFactory";
import configureMenuItemsConfig from "./childPanels/configureMenuItemsConfig";
import { ButtonVariantTypes } from "widgets/ZButtonWidget/constants";
import { get } from "lodash";

export default {
  sectionName: "Basic",
  hidden: (props: ZTableWidgetProps, propertyPath: string) => {
    return hideByColumnType(
      props,
      propertyPath,
      [
        ColumnTypes.BUTTON,
        ColumnTypes.ACTION_ARRAY,
        ColumnTypes.ICON_BUTTON,
        ColumnTypes.MENU_BUTTON,
        ColumnTypes.CHIP,
        ColumnTypes.TEXT,
      ],
      true,
    );
  },
  children: [
    {
      propertyName: "iconName",
      label: "Icon",
      helpText: "Sets the icon to be used for the icon button",
      hidden: (props: ZTableWidgetProps, propertyPath: string) => {
        return hideByColumnType(props, propertyPath, [ColumnTypes.ICON_BUTTON]);
      },
      updateHook: updateIconAlignment,
      dependencies: ["primaryColumns", "columnOrder"],
      controlType: "ZICON_SELECT",
      customJSControl: "TABLE_COMPUTE_VALUE",
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
      validation: {
        type: ValidationTypes.TEXT,
      },
    },
    {
      propertyName: "actionArray",
      label: "Action Array",
      controlType: "HEADER_ACTION",
      isBindProperty: false,
      isTriggerProperty: false,
      dependencies: ["primaryColumns", "columnOrder"],
      customJSControl: "TABLE_COMPUTE_VALUE",
      hidden: (props: ZTableWidgetProps, propertyPath: string) => {
        return hideByColumnType(props, propertyPath, [
          ColumnTypes.ACTION_ARRAY,
        ]);
      },
      panelConfig: {
        editableTitle: true,
        titlePropertyName: "label",
        panelIdPropertyName: "id",
        updateHook: (
          props: any,
          propertyPath: string,
          propertyValue: string,
        ) => {
          return [
            {
              propertyPath,
              propertyValue,
            },
          ];
        },
        contentChildren: [
          {
            sectionName: "Data",
            children: [
              {
                propertyName: "actionType",
                label: "Action type",
                controlType: "DROP_DOWN",
                fullWidth: true,
                options: [
                  {
                    label: "Button",
                    value: "BUTTON",
                  },
                  {
                    label: "Menu",
                    value: "MENU",
                  },
                ],
                defaultValue: "BUTTON",
                isJSConvertible: true,
                isBindProperty: true,
                isTriggerProperty: false,
                validation: {
                  type: ValidationTypes.TEXT,
                  params: {
                    allowedValues: ["BUTTON", "MENU"],
                  },
                },
              },
              {
                propertyName: "menuItemsSource",
                helpText: "Sets the source for the menu items",
                label: "Menu items source",
                controlType: "ICON_TABS",
                defaultValue: MenuItemsSource.STATIC,
                fullWidth: true,
                options: [
                  {
                    label: "Static",
                    value: MenuItemsSource.STATIC,
                  },
                  {
                    label: "Dynamic",
                    value: MenuItemsSource.DYNAMIC,
                  },
                ],
                isJSConvertible: false,
                isBindProperty: false,
                isTriggerProperty: false,
                validation: { type: ValidationTypes.TEXT },
                updateHook: updateMenuItemsSource,
                dependencies: [
                  "sourceData",
                  "configureMenuItems",
                  "headerActions",
                ],
                hidden: (props: ZTableWidgetProps, propertyPath: string) => {
                  const baseProperty = getBasePropertyPath(propertyPath);
                  const actionType = get(
                    props,
                    `${baseProperty}.actionType`,
                    "",
                  );
                  return actionType === "BUTTON";
                },
              },
              {
                helpText:
                  "Takes in an array of items to display the menu items.",
                propertyName: "sourceData",
                label: "Source data",
                placeholderText: "{{Query1.data}}",
                controlType: "TABLE_COMPUTE_VALUE",
                inputType: "ARRAY",
                isBindProperty: true,
                isTriggerProperty: false,
                validation: {
                  type: ValidationTypes.ARRAY,
                  params: {
                    required: true,
                    default: [],
                    children: {
                      type: ValidationTypes.ARRAY,
                      params: {
                        required: true,
                        default: [],
                        children: {
                          type: ValidationTypes.UNION,
                          params: {
                            required: true,
                            types: [
                              {
                                type: ValidationTypes.TEXT,
                                params: {
                                  required: true,
                                },
                              },
                              {
                                type: ValidationTypes.NUMBER,
                                params: {
                                  required: true,
                                },
                              },
                              {
                                type: ValidationTypes.OBJECT,
                                params: {
                                  required: true,
                                },
                              },
                            ],
                          },
                        },
                      },
                    },
                  },
                },
                evaluationSubstitutionType:
                  EvaluationSubstitutionType.SMART_SUBSTITUTE,
                hidden: (props: ZTableWidgetProps, propertyPath: string) => {
                  return hideByMenuItemsSource(
                    props,
                    propertyPath,
                    MenuItemsSource.STATIC,
                  );
                },
                dependencies: [
                  "primaryColumns",
                  "columnOrder",
                  "menuItemsSource",
                ],
              },
              {
                helpText: "Configure how each menu item will appear.",
                propertyName: "configureMenuItems",
                controlType: "OPEN_CONFIG_PANEL",
                buttonConfig: {
                  label: "Configure",
                  icon: "settings-2-line",
                },
                label: "Configure menu items",
                isBindProperty: false,
                isTriggerProperty: false,
                hidden: (props: ZTableWidgetProps, propertyPath: string) => {
                  return hideByMenuItemsSource(
                    props,
                    propertyPath,
                    MenuItemsSource.STATIC,
                  );
                },
                dependencies: ["menuItemsSource", "sourceData"],
                panelConfig: configureMenuItemsConfig,
              },
              {
                dependencies: ["actionType", "menuItemsSource"],
                helpText: "Menu items",
                propertyName: "menuItems",
                controlType: "MENU_ITEMS",
                label: "Menu items",
                isBindProperty: false,
                isTriggerProperty: false,
                hidden: (props: ZTableWidgetProps, propertyPath: string) => {
                  const baseProperty = getBasePropertyPath(propertyPath);
                  const actionType = get(
                    props,
                    `${baseProperty}.actionType`,
                    "",
                  );
                  return (
                    actionType === "BUTTON" ||
                    hideByMenuItemsSource(
                      props,
                      propertyPath,
                      MenuItemsSource.DYNAMIC,
                    )
                  );
                },
                panelConfig: {
                  editableTitle: true,
                  titlePropertyName: "label",
                  panelIdPropertyName: "id",
                  updateHook: (
                    props: any,
                    propertyPath: string,
                    propertyValue: string,
                  ) => {
                    return [
                      {
                        propertyPath,
                        propertyValue,
                      },
                    ];
                  },
                  contentChildren: [
                    {
                      sectionName: "Label",
                      children: [
                        {
                          propertyName: "label",
                          helpText: "Sets the label of a menu item",
                          label: "Text",
                          controlType: "INPUT_TEXT",
                          placeholderText: "Enter label",
                          isBindProperty: true,
                          isTriggerProperty: false,
                          validation: { type: ValidationTypes.TEXT },
                        },
                      ],
                    },
                    {
                      sectionName: "General",
                      children: [
                        // {
                        //   propertyName: "href",
                        //   label: "href",
                        //   controlType: "INPUT_TEXT",
                        //   isBindProperty: true,
                        //   isTriggerProperty: false,
                        //   validation: { type: ValidationTypes.TEXT },
                        // },
                        {
                          propertyName: "isVisible",
                          helpText: "Controls the visibility of menu item",
                          label: "Visible",
                          controlType: "SWITCH",
                          isJSConvertible: true,
                          isBindProperty: true,
                          isTriggerProperty: false,
                          validation: {
                            type: ValidationTypes.BOOLEAN,
                          },
                        },
                        {
                          propertyName: "isDisabled",
                          helpText: "Disables menu item",
                          label: "Disabled",
                          controlType: "SWITCH",
                          isJSConvertible: true,
                          isBindProperty: true,
                          isTriggerProperty: false,
                          validation: {
                            type: ValidationTypes.BOOLEAN,
                          },
                        },
                        {
                          propertyName: "divider",
                          helpText: "Divider with next button in menu dropdown",
                          label: "Divider",
                          controlType: "SWITCH",
                          isJSConvertible: true,
                          isBindProperty: true,
                          isTriggerProperty: false,
                          validation: {
                            type: ValidationTypes.BOOLEAN,
                          },
                        },
                      ],
                    },
                    {
                      sectionName: "Events",
                      children: [
                        {
                          helpText: "when the menu item is clicked",
                          propertyName: "onClick",
                          label: "onClick",
                          controlType: "ACTION_SELECTOR",
                          isJSConvertible: true,
                          isBindProperty: true,
                          isTriggerProperty: true,
                        },
                      ],
                    },
                  ],
                  styleChildren: [
                    {
                      sectionName: "Icon",
                      children: [
                        {
                          propertyName: "icon",
                          label: "Icon",
                          controlType: "ZICON_SELECT",
                          isJSConvertible: true,
                          isBindProperty: true,
                          isTriggerProperty: false,
                          validation: { type: ValidationTypes.TEXT },
                        },
                        {
                          propertyName: "startIcon",
                          label: "StartIcon",
                          controlType: "ZICON_SELECT",
                          isJSConvertible: true,
                          isBindProperty: true,
                          isTriggerProperty: false,
                          validation: { type: ValidationTypes.TEXT },
                        },
                        {
                          propertyName: "endIcon",
                          label: "EndIcon",
                          controlType: "ZICON_SELECT",
                          isJSConvertible: true,
                          isBindProperty: true,
                          isTriggerProperty: false,
                          validation: { type: ValidationTypes.TEXT },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
          },
          {
            sectionName: "Label",
            children: [
              {
                propertyName: "label",
                helpText: "Sets the label of a menu item",
                label: "Text",
                controlType: "INPUT_TEXT",
                placeholderText: "Enter label",
                isBindProperty: true,
                isTriggerProperty: false,
                validation: { type: ValidationTypes.TEXT },
              },
              {
                propertyName: "icon",
                label: "Icon",
                controlType: "ZICON_SELECT",
                isJSConvertible: true,
                isBindProperty: true,
                isTriggerProperty: false,
                validation: { type: ValidationTypes.TEXT },
              },
              {
                propertyName: "startIcon",
                label: "StartIcon",
                controlType: "ZICON_SELECT",
                isJSConvertible: true,
                isBindProperty: true,
                isTriggerProperty: false,
                validation: { type: ValidationTypes.TEXT },
              },
              {
                propertyName: "endIcon",
                label: "EndIcon",
                controlType: "ZICON_SELECT",
                isJSConvertible: true,
                isBindProperty: true,
                isTriggerProperty: false,
                validation: { type: ValidationTypes.TEXT },
              },
              {
                helpText: "Show help text or details about current action",
                propertyName: "tooltip",
                label: "Tooltip",
                controlType: "INPUT_TEXT",
                isBindProperty: true,
                isTriggerProperty: false,
                validation: { type: ValidationTypes.TEXT },
              },
            ],
          },
          {
            sectionName: "General",
            children: [
              {
                propertyName: "isVisible",
                helpText: "Controls the visibility of the widget",
                label: "Visible",
                controlType: "SWITCH",
                isJSConvertible: true,
                isBindProperty: true,
                isTriggerProperty: false,
                validation: { type: ValidationTypes.BOOLEAN },
              },
              {
                propertyName: "isDisabled",
                helpText: "Disables input to the widget",
                label: "Disabled",
                controlType: "SWITCH",
                isJSConvertible: true,
                isBindProperty: true,
                isTriggerProperty: false,
                validation: { type: ValidationTypes.BOOLEAN },
              },
              {
                propertyName: "divider",
                helpText: "Divider with next button in menu dropdown",
                label: "Divider",
                controlType: "SWITCH",
                isJSConvertible: true,
                isBindProperty: true,
                isTriggerProperty: false,
                validation: {
                  type: ValidationTypes.BOOLEAN,
                },
              },
            ],
          },
          {
            sectionName: "Events",
            children: [
              {
                propertyName: "onClick",
                label: "onClick",
                controlType: "ACTION_SELECTOR",
                isJSConvertible: true,
                isBindProperty: true,
                isTriggerProperty: true,
                dependencies: ["actionType"],
              },
            ],
          },
        ],
        styleChildren: [
          {
            sectionName: "Variant",
            children: [
              {
                propertyName: "variant",
                dependencies: ["actionType"],
                label: "Variant",
                controlType: "ICON_TABS",
                defaultValue: ButtonVariantTypes.CONTAINED,
                fullWidth: true,
                helpText: "Sets the variant of the button",
                options: [
                  {
                    label: "Contained",
                    value: ButtonVariantTypes.CONTAINED,
                  },
                  {
                    label: "Tertiary",
                    value: ButtonVariantTypes.TERTIARY,
                  },
                  {
                    label: "Text",
                    value: ButtonVariantTypes.TEXT,
                  },
                ],
                isJSConvertible: true,
                isBindProperty: true,
                isTriggerProperty: false,
                validation: {
                  type: ValidationTypes.TEXT,
                  params: {
                    allowedValues: [
                      ButtonVariantTypes.CONTAINED,
                      // ButtonVariantTypes.OUTLINED,
                      ButtonVariantTypes.TERTIARY,
                    ],
                    default: ButtonVariantTypes.CONTAINED,
                  },
                },
              },
            ],
          },
          {
            sectionName: "Color",
            children: [
              {
                propertyName: "buttonColor",
                helpText: "Changes the color of the button",
                label: "Button color",
                controlType: "COLOR_PICKER",
                isJSConvertible: true,
                isBindProperty: true,
                isTriggerProperty: false,
                validation: { type: ValidationTypes.TEXT },
              },
            ],
          },
        ],
      },
    },
    {
      propertyName: "maxActionDisplay",
      label: "Max Action Display",
      controlType: "INPUT_TEXT",
      isBindProperty: true,
      isTriggerProperty: false,
      validation: { type: ValidationTypes.NUMBER },
      dependencies: ["primaryColumns", "columnOrder"],
      customJSControl: "TABLE_COMPUTE_VALUE",
      hidden: (props: ZTableWidgetProps, propertyPath: string) => {
        return hideByColumnType(props, propertyPath, [
          ColumnTypes.ACTION_ARRAY,
        ]);
      },
    },
    {
      propertyName: "buttonLabel",
      label: "Text",
      helpText: "Sets the label of the button",
      controlType: "TABLE_COMPUTE_VALUE",
      defaultValue: "Action",
      hidden: (props: ZTableWidgetProps, propertyPath: string) => {
        return hideByColumnType(props, propertyPath, [ColumnTypes.BUTTON]);
      },
      dependencies: ["primaryColumns", "columnOrder"],
      isBindProperty: true,
      isTriggerProperty: false,
    },
    {
      propertyName: "chipLabel",
      label: "Label",
      helpText: "Sets the label of the chip",
      controlType: "TABLE_COMPUTE_VALUE",
      defaultValue: "Label",
      hidden: (props: ZTableWidgetProps, propertyPath: string) => {
        return hideByColumnType(props, propertyPath, [ColumnTypes.CHIP]);
      },
      dependencies: ["primaryColumns", "columnOrder"],
      isBindProperty: true,
      isTriggerProperty: false,
    },
    {
      sectionName: "Chip State Value",
      hidden: (props: ZTableWidgetProps, propertyPath: string) => {
        return hideByColumnType(props, propertyPath, [ColumnTypes.CHIP], true);
      },
      children: [
        {
          propertyName: "chipStateError",
          label: "Error",
          controlType: "INPUT_TEXT",
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.TEXT },
        },
        {
          propertyName: "chipStatePrimary",
          label: "Primary",
          controlType: "INPUT_TEXT",
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.TEXT },
        },
        {
          propertyName: "chipStateSuccess",
          label: "Success",
          controlType: "INPUT_TEXT",
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.TEXT },
        },
        {
          propertyName: "chipStateWarning",
          label: "Warning",
          controlType: "INPUT_TEXT",
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.TEXT },
        },
        {
          propertyName: "chipStateSecondary",
          label: "Secondary",
          controlType: "INPUT_TEXT",
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.TEXT },
        },
        {
          propertyName: "chipStateInfo",
          label: "Info",
          controlType: "INPUT_TEXT",
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.TEXT },
        },
        {
          propertyName: "chipStateIndeterminate",
          label: "Indeterminate",
          controlType: "INPUT_TEXT",
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.TEXT },
        },
      ],
    },
    {
      propertyName: "chipIcon",
      label: "Icon",
      helpText: "Sets the icon of the chip",
      controlType: "ZICON_SELECT",
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
      validation: { type: ValidationTypes.TEXT },
      hidden: (props: ZTableWidgetProps, propertyPath: string) => {
        return hideByColumnType(props, propertyPath, [ColumnTypes.CHIP]);
      },
      dependencies: ["primaryColumns", "columnOrder"],
    },
    {
      propertyName: "menuButtonLabel",
      label: "Text",
      helpText: "Sets the label of the button",
      controlType: "TABLE_COMPUTE_VALUE",
      defaultValue: "Open Menu",
      hidden: (props: ZTableWidgetProps, propertyPath: string) => {
        return hideByColumnType(props, propertyPath, [ColumnTypes.MENU_BUTTON]);
      },
      dependencies: ["primaryColumns", "columnOrder"],
      isBindProperty: true,
      isTriggerProperty: false,
    },
    {
      propertyName: "menuItemsSource",
      helpText: "Sets the source for the menu items",
      label: "Menu items source",
      controlType: "ICON_TABS",
      fullWidth: true,
      defaultValue: MenuItemsSource.STATIC,
      options: [
        {
          label: "Static",
          value: MenuItemsSource.STATIC,
        },
        {
          label: "Dynamic",
          value: MenuItemsSource.DYNAMIC,
        },
      ],
      isJSConvertible: false,
      isBindProperty: false,
      isTriggerProperty: false,
      validation: { type: ValidationTypes.TEXT },
      updateHook: updateMenuItemsSource,
      dependencies: [
        "primaryColumns",
        "columnOrder",
        "sourceData",
        "configureMenuItems",
      ],
      hidden: (props: ZTableWidgetProps, propertyPath: string) => {
        return hideByColumnType(
          props,
          propertyPath,
          [ColumnTypes.MENU_BUTTON],
          false,
        );
      },
    },
    {
      helpText: "Takes in an array of items to display the menu items.",
      propertyName: "sourceData",
      label: "Source data",
      controlType: "TABLE_COMPUTE_VALUE",
      placeholderText: "{{Query1.data}}",
      isBindProperty: true,
      isTriggerProperty: false,
      validation: {
        type: ValidationTypes.ARRAY,
        params: {
          required: true,
          default: [],
          children: {
            type: ValidationTypes.ARRAY,
            params: {
              required: true,
              default: [],
              children: {
                type: ValidationTypes.UNION,
                params: {
                  required: true,
                  types: [
                    {
                      type: ValidationTypes.TEXT,
                      params: {
                        required: true,
                      },
                    },
                    {
                      type: ValidationTypes.NUMBER,
                      params: {
                        required: true,
                      },
                    },
                    {
                      type: ValidationTypes.OBJECT,
                      params: {
                        required: true,
                      },
                    },
                  ],
                },
              },
            },
          },
        },
      },
      evaluationSubstitutionType: EvaluationSubstitutionType.SMART_SUBSTITUTE,
      hidden: (props: ZTableWidgetProps, propertyPath: string) => {
        return (
          hideByColumnType(
            props,
            propertyPath,
            [ColumnTypes.MENU_BUTTON],
            false,
          ) ||
          hideByMenuItemsSource(props, propertyPath, MenuItemsSource.STATIC)
        );
      },
      dependencies: ["primaryColumns", "columnOrder", "menuItemsSource"],
    },
    {
      helpText: "Configure how each menu item will appear.",
      propertyName: "configureMenuItems",
      controlType: "OPEN_CONFIG_PANEL",
      buttonConfig: {
        label: "Configure",
        icon: "settings-2-line",
      },
      label: "Configure menu items",
      isBindProperty: false,
      isTriggerProperty: false,
      hidden: (props: ZTableWidgetProps, propertyPath: string) =>
        hideByColumnType(
          props,
          propertyPath,
          [ColumnTypes.MENU_BUTTON],
          false,
        ) ||
        hideIfMenuItemsSourceDataIsFalsy(props, propertyPath) ||
        hideByMenuItemsSource(props, propertyPath, MenuItemsSource.STATIC),
      dependencies: [
        "primaryColumns",
        "columnOrder",
        "menuItemsSource",
        "sourceData",
      ],
      panelConfig: configureMenuItemsConfig,
    },
    {
      helpText: "Menu items",
      propertyName: "menuItems",
      controlType: "MENU_ITEMS",
      label: "Menu items",
      isBindProperty: false,
      isTriggerProperty: false,
      hidden: (props: ZTableWidgetProps, propertyPath: string) => {
        return (
          hideByColumnType(
            props,
            propertyPath,
            [ColumnTypes.MENU_BUTTON],
            false,
          ) ||
          hideByMenuItemsSource(props, propertyPath, MenuItemsSource.DYNAMIC)
        );
      },
      dependencies: ["primaryColumns", "columnOrder"],
      panelConfig: {
        editableTitle: true,
        titlePropertyName: "label",
        panelIdPropertyName: "id",
        dependencies: ["primaryColumns", "columnOrder"],
        contentChildren: [
          {
            sectionName: "Basic",
            children: [
              {
                propertyName: "label",
                helpText: "Sets the label of a menu item",
                label: "Text",
                controlType: "INPUT_TEXT",
                placeholderText: "Enter label",
                isBindProperty: true,
                isTriggerProperty: false,
                validation: { type: ValidationTypes.TEXT },
                dependencies: ["primaryColumns", "columnOrder"],
              },
              {
                helpText: "when the menu item is clicked",
                propertyName: "onClick",
                label: "onClick",
                controlType: "ACTION_SELECTOR",
                isJSConvertible: true,
                isBindProperty: true,
                isTriggerProperty: true,
                dependencies: ["primaryColumns", "columnOrder"],
              },
            ],
          },
          {
            sectionName: "General",
            children: [
              {
                propertyName: "isVisible",
                helpText: "Controls the visibility of the widget",
                label: "Visible",
                controlType: "SWITCH",
                customJSControl: "TABLE_COMPUTE_VALUE",
                isJSConvertible: true,
                isBindProperty: true,
                isTriggerProperty: false,
                validation: {
                  type: ValidationTypes.ARRAY_OF_TYPE_OR_TYPE,
                  params: {
                    type: ValidationTypes.BOOLEAN,
                  },
                },
                dependencies: ["primaryColumns", "columnOrder"],
              },
              {
                propertyName: "isDisabled",
                helpText: "Disables input to the widget",
                label: "Disabled",
                controlType: "SWITCH",
                customJSControl: "TABLE_COMPUTE_VALUE",
                isJSConvertible: true,
                isBindProperty: true,
                isTriggerProperty: false,
                validation: {
                  type: ValidationTypes.ARRAY_OF_TYPE_OR_TYPE,
                  params: {
                    type: ValidationTypes.BOOLEAN,
                  },
                },
                dependencies: ["primaryColumns", "columnOrder"],
              },
            ],
          },
        ],
        styleChildren: [
          {
            sectionName: "Icon",
            children: [
              {
                propertyName: "iconName",
                label: "Icon",
                helpText: "Sets the icon to be used for a menu item",
                controlType: "ICON_SELECT",
                isBindProperty: false,
                isTriggerProperty: false,
                validation: { type: ValidationTypes.TEXT },
                dependencies: ["primaryColumns", "columnOrder"],
              },
              {
                propertyName: "iconAlign",
                label: "Position",
                helpText: "Sets the icon alignment of a menu item",
                controlType: "ICON_TABS",
                defaultValue: "left",
                fullWidth: false,
                options: [
                  {
                    startIcon: "skip-left-line",
                    value: "left",
                  },
                  {
                    startIcon: "skip-right-line",
                    value: "right",
                  },
                ],
                isBindProperty: false,
                isTriggerProperty: false,
                validation: { type: ValidationTypes.TEXT },
                dependencies: ["primaryColumns", "columnOrder"],
              },
            ],
          },
          {
            sectionName: "Color",
            children: [
              {
                propertyName: "textColor",
                helpText: "Sets the text color of a menu item",
                label: "Text color",
                controlType: "PRIMARY_COLUMNS_COLOR_PICKER_V2",
                customJSControl: "TABLE_COMPUTE_VALUE",
                isJSConvertible: true,
                isBindProperty: true,
                isTriggerProperty: false,
                dependencies: ["primaryColumns", "columnOrder"],
                validation: {
                  type: ValidationTypes.ARRAY_OF_TYPE_OR_TYPE,
                  params: {
                    type: ValidationTypes.TEXT,
                    params: {
                      regex: /^(?![<|{{]).+/,
                    },
                  },
                },
              },
              {
                propertyName: "backgroundColor",
                helpText: "Sets the background color of a menu item",
                label: "Background color",
                controlType: "PRIMARY_COLUMNS_COLOR_PICKER_V2",
                customJSControl: "TABLE_COMPUTE_VALUE",
                isJSConvertible: true,
                isBindProperty: true,
                isTriggerProperty: false,
                dependencies: ["primaryColumns", "columnOrder"],
                validation: {
                  type: ValidationTypes.ARRAY_OF_TYPE_OR_TYPE,
                  params: {
                    type: ValidationTypes.TEXT,
                    params: {
                      regex: /^(?![<|{{]).+/,
                    },
                  },
                },
              },
              {
                propertyName: "iconColor",
                helpText: "Sets the icon color of a menu item",
                label: "Icon color",
                controlType: "PRIMARY_COLUMNS_COLOR_PICKER_V2",
                isBindProperty: false,
                isTriggerProperty: false,
                dependencies: ["primaryColumns", "columnOrder"],
              },
            ],
          },
        ],
      },
    },
    {
      helpText: "when the button is clicked",
      propertyName: "onClick",
      label: "onClick",
      controlType: "ACTION_SELECTOR",
      additionalAutoComplete: (props: ZTableWidgetProps) => ({
        currentRow: Object.assign(
          {},
          ...Object.keys(props.primaryColumns).map((key) => ({
            [key]: "",
          })),
        ),
      }),
      isJSConvertible: true,
      dependencies: ["primaryColumns", "columnOrder"],
      isBindProperty: true,
      isTriggerProperty: true,
      hidden: (props: ZTableWidgetProps, propertyPath: string) => {
        return hideByColumnType(props, propertyPath, [
          ColumnTypes.BUTTON,
          ColumnTypes.ICON_BUTTON,
          ColumnTypes.CHIP,
          ColumnTypes.TEXT,
        ]);
      },
    },
  ],
};
